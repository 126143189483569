import React, { Component } from "react";
import { Link } from "gatsby";
import LazyLoad from "react-lazyload";
import $ from "jquery";
import { Layout, Loader, HomeClients, HomeLineAnimation } from "../components";

class IndexPage extends Component {
  onClientAnimation = () => {
    const plus = $(".line-animation__thumb");
    const isAnimated = plus.hasClass("running");
    if (isAnimated) {
      plus.addClass("spin");
      setTimeout(() => {
        plus.removeClass("spin");
      }, 500);
    }
  };

  render() {
    const {
      pageContext: { slug },
    } = this.props;

    return (
      <Layout
        seo={{
          title: "Grupa ADream - Agencja reklamowa interaktywna w Krakowie",
          href: slug,
          lang: "pl",
        }}
        langSwitch={{
          langKey: "en",
          langSlug: "/en/",
        }}
        scroll
      >
        <HomeLineAnimation />
        <LazyLoad once offset={1} placeholder={<Loader />}>
          <section className="container-fluid home_section_1">
            <div className="row home_section_1__header align-items-center">
              <div className="col-lg-5 col_1">
                <div className="inner">
                  <h1 className="for_mobile">
                    <span>Tworzymy</span> <span>marki</span> <br />{" "}
                    <span> i&nbsp;&nbsp;produkty</span> <span>cyfrowe<sup>*</sup></span>
                    <p className="annotation__mobile"><sup>*</sup>które &nbsp; działają</p>
                  </h1>

                  <h1
                    className="glitch glitch--1 words chars splitting for_desktop"
                    data-scroll="out"
                    data-splitting=""
                  >
                  Tworzymy marki <br/>
                  i{" "} 
                  <span className="word" data-word="produkty">
                    <span className="char" data-char="p">
                      p
                    </span>
                    <span className="char" data-char="r">
                      r
                    </span>
                    <span className="char" data-char="o">
                      o
                    </span>
                    <span className="char" data-char="d">
                      d
                    </span>
                    <span className="char" data-char="u">
                      u
                    </span>
                    <span className="char" data-char="k">
                      k
                    </span>
                    <span className="char" data-char="t">
                      t
                    </span>
                    <span className="char" data-char="y">
                      y
                    </span>
                  </span>{" "}
                  <span className="word" data-word="cyfrowe">
                    <span className="char" data-char="c">
                      c
                    </span>
                    <span className="char" data-char="y">
                      y
                    </span>
                    <span className="char" data-char="f">
                      f
                    </span>
                    <span className="char" data-char="r">
                      r
                    </span>
                    <span className="char" data-char="o">
                      o
                    </span>
                    <span className="char" data-char="w">
                      w
                    </span>
                    <span className="char" data-char="e">
                      e
                    </span>
                  </span>
                  <sup className="star">*</sup>
                  </h1>
                  <span className="annotation">*które działają</span>


                </div>
                <Link to="/projekty" className="btn_custom">
                    Projekty
                </Link>
              </div>
              <div className="col-lg-7 col_2">
                <video
                  width="100%"
                  height="100%"
                  loop
                  autoPlay
                  playsInline
                  muted
                  data-setup="{}"
                >
                  <source
                    src={require("../assets/video/video_top.mp4")}
                    type="video/mp4"
                  />
                </video>
                <img
                  className="img-fluid"
                  src={require("../assets/img/home_section_1_a.svg")}
                  alt=""
                />
              </div>
            </div>
          </section>
          <div className="home_mobile_desc">
            <p>
              <span>Kompetencje</span>
              Połączyliśmy kompleksowość obsługi z wysokim stopniem
              specjalizacji we wszystkich jej obszarach. Jako agencja
              interaktywna i reklamowa odpowiadamy za działania takie jak:{" "}
              <br /> Strategia, Branding, UX, Design, Development, Marketing.
            </p>
          </div>
        </LazyLoad>
        <section className="container-fluid home_section_2">
          <div className="text-center">
            <h2>Projekty</h2>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <Link to="/projekty/rspwn" className="portfolio_tile">
                <h3>
                  RSPWN.GG <span>Logo / Sklep internetowy</span>
                </h3>
                <LazyLoad offset={100}>
                  <div
                    className="portfolio_tile-back"
                    style={{
                      backgroundImage: `url(${require("../assets/img/portfolio_grid/rspwn_back.png")})`,
                    }}
                  ></div>
                </LazyLoad>
                <LazyLoad>
                  <img
                    className="img-fluid"
                    src={require("../assets/img/portfolio_grid/rspwn_front.png")}
                    alt="Projekty RSPWN.GG"
                  />
                </LazyLoad>
              </Link>
            </div>
            <div className="col-lg-4 col-md-6">
              <Link to="/projekty/lewandowski" className="portfolio_tile">
                <h3>
                  Lewandowski Power Team <span>Logo / Projekt opakowania</span>
                </h3>
                <LazyLoad offset={100}>
                  <div
                    className="portfolio_tile-back"
                    style={{
                      backgroundImage: `url(${require("../assets/img/portfolio_grid/lewandowski_back.png")})`,
                    }}
                  ></div>
                </LazyLoad>
                <LazyLoad>
                  <img
                    className="img-fluid"
                    src={require("../assets/img/portfolio_grid/lewandowski_front.png")}
                    alt=""
                  />
                </LazyLoad>
              </Link>
            </div>
            <div className="col-lg-4 col-md-6">
              <Link
                to="/projekty/green-mountain-resort"
                className="portfolio_tile gmr"
              >
                <h3>
                  Green Mountain Resort{" "}
                  <span>
                    Strona internetowa / Prezentacje / Katalogi / Kampania
                    leadowa
                  </span>
                </h3>
                <LazyLoad offset={100}>
                  <div
                    className="portfolio_tile-back"
                    style={{
                      backgroundImage: `url(${require("../assets/img/portfolio_grid/gmr_back.png")})`,
                    }}
                  ></div>
                </LazyLoad>
                <LazyLoad>
                  <img
                    className="img-fluid"
                    src={require("../assets/img/portfolio_grid/gmr_front.png")}
                    alt=""
                  />
                </LazyLoad>
              </Link>
            </div>
            <div className="col-lg-4 col-md-6">
              <Link to="/projekty/euforia" className="portfolio_tile">
                <h3>
                  Perfumeria Euforia{" "}
                  <span>Kampania produktowa / Case Study</span>
                </h3>
                <LazyLoad offset={350}>
                  <div
                    className="portfolio_tile-back"
                    style={{
                      backgroundImage: `url(${require("../assets/img/portfolio_grid/euforia_back.png")})`,
                    }}
                  ></div>
                </LazyLoad>
                <LazyLoad>
                  <img
                    className="img-fluid"
                    src={require("../assets/img/portfolio_grid/euforia_front.png")}
                    alt=""
                  />
                </LazyLoad>
              </Link>
            </div>
            <div className="col-lg-4 col-md-6">
              <Link to="/projekty/gg-brewery" className="portfolio_tile">
                <h3>
                  GG Brewery{" "}
                  <span>Logo browaru / Logo piw / Projekty etykiet</span>
                </h3>
                <LazyLoad offset={350}>
                  <div
                    className="portfolio_tile-back"
                    style={{
                      backgroundImage: `url(${require("../assets/img/portfolio_grid/gg_back.png")})`,
                    }}
                  ></div>
                </LazyLoad>
                <LazyLoad>
                  <img
                    className="img-fluid"
                    src={require("../assets/img/portfolio_grid/gg_front.png")}
                    alt=""
                  />
                </LazyLoad>
              </Link>
            </div>
            <div className="col-lg-4 col-md-6">
              <Link to="/projekty/zmigrod" className="portfolio_tile">
                <h3>
                  Nowy Żmigród{" "}
                  <span>
                    Logo / Branding / Projekty opakowań / Materiały promocyjne /
                    Katalogi / Strona internetowa
                  </span>
                </h3>
                <LazyLoad offset={350}>
                  <div
                    className="portfolio_tile-back"
                    style={{
                      backgroundImage: `url(${require("../assets/img/portfolio_grid/zmigrod_back.png")})`,
                    }}
                  ></div>
                </LazyLoad>
                <LazyLoad>
                  <img
                    className="img-fluid"
                    src={require("../assets/img/portfolio_grid/zmigrod_front.png")}
                    alt=""
                  />
                </LazyLoad>
              </Link>
            </div>
          </div>
          <div className="home_section_2--action">
            <Link to="/projekty" className="btn_custom bigger">
              Wszystkie projekty
            </Link>
          </div>
        </section>
        <section className="container-fluid home_section_3">
          <div className="text-center">
            <h2>Klienci</h2>
          </div>
          <HomeClients onClientAnimation={() => this.onClientAnimation()} />
        </section>
      </Layout>
    );
  }
}

export default IndexPage;
